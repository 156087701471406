import React, { useState, useEffect } from 'react'
import { GetUserProfileOutput, getUserProfile } from '../../src/sdk/user'
import * as userAPI from '../../src/sdk/user'
import './Login.css'
import { useToken } from '../hooks/useToken'
import { useStateProxy } from 'use-state-proxy'
import { Link } from 'react-router-dom'

function Login() {
  const state = useStateProxy({
    username: '',
    password: '',
    confirm_password: '',
    is_username_used: false,
  })
  const mode = !state.username || state.is_username_used ? 'login' : 'register'

  const { token, setToken, logout } = useToken()

  const [profile, setProfile] = useState<GetUserProfileOutput | null>(null)

  useEffect(() => {
    if (!state.username) return
    userAPI
      .checkUsername({ query: { username: state.username } })
      .then(json => (state.is_username_used = json.is_registered))
  }, [state.username])

  useEffect(() => {
    if (token) {
      getUserProfile({}).then(setProfile)
    }
  }, [token])

  async function login() {
    let json = await userAPI.login({
      body: {
        username: state.username,
        password: state.password,
      },
    })
    setToken(json.token)
  }

  async function register() {
    let json = await userAPI.register({
      body: {
        username: state.username,
        password: state.password,
      },
    })
    setToken(json.token)
  }

  return (
    <>
      <div className="body">
        <div className="reg-container">
          {token && profile ? (
            <>
              <h1>Welcome, </h1>
              {profile.username}
              <Link to="/app/library">
                <button>Go to Library</button>
              </Link>
            </>
          ) : (
            <form>
              <h1>{mode == 'register' ? 'Register' : 'Login / Register'}</h1>
              <input
                type="text"
                autoComplete="username"
                onChange={event => (state.username = event.target.value)}
                placeholder="Username"
              />
              <input
                type="password"
                autoComplete="current-password"
                onChange={event => (state.password = event.target.value)}
                placeholder="Password"
              />
              {mode == 'register' ? (
                <input
                  type="password"
                  autoComplete="off"
                  onChange={event =>
                    (state.confirm_password = event.target.value)
                  }
                  placeholder="Confirm Password"
                />
              ) : null}
            </form>
          )}

          {token ? (
            <button onClick={logout}>Logout</button>
          ) : mode == 'register' ? (
            <button
              disabled={state.password != state.confirm_password}
              onClick={register}
            >
              Register
            </button>
          ) : (
            <button onClick={login}>Login / Register</button>
          )}
        </div>
      </div>
    </>
  )
}

export default Login
