// This file is generated automatically
// Don't edit this file directly

import { call, toParams } from './utils'

export let api_origin = '/api'

// GET /api/skybox/styles
export function getSkyboxStyleList(input: GetSkyboxStyleListInput): Promise<GetSkyboxStyleListOutput & { error?: string }> {
  return call('GET', api_origin + `/skybox/styles`)
}
export type GetSkyboxStyleListInput = {
}
export type GetSkyboxStyleListOutput = {
  styles: Array<{
    id: string
    name: string
  }>
}
